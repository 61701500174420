import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { isPast, lastDayOfMonth } from 'date-fns';
import React, { useCallback, useRef, useState } from 'react';
import * as Yup from 'yup';
import { validateCep, validateCPF } from 'validations-br';
import axios from 'axios';

import ReCAPTCHA from 'react-google-recaptcha';
import Address from '../../../../components/Address';
import Button from '../../../../components/Button';
import Input from '../../../../components/Input';
import InputMask from '../../../../components/InputMask';
import api from '../../../../services/api';

import { Container } from './styles';
import getValidationErrors from '../../../../utils/getValidationErrors';
import { useLoading } from '../../../../hooks/loading';
import { useStep } from '../../../../hooks/steps';
import { useCharge } from '../../../../hooks/charge';

import flagsImage from '../../../../assets/flags.png';
import { useToast } from '../../../../hooks/toast';
import getPagarmeErrors from '../../../../utils/getPagarmeErrors';
import { useProduct } from '../../../../hooks/product';
import removeSpecialCharacters from '../../../../utils/removeSpecialCharacteres';
import generateDeviceId from '../../../../services/fingerprint';

interface ICreditCardRegistrationData {
  cpf: string;
  name: string;
  zipcode: string;
  street: string;
  city: string;
  state: string;
  number: string;
  expiration_date: string;
  cvv: string;
}

const CreditCard: React.FC = () => {
  const { product } = useProduct();
  const { startLoading, stopLoading } = useLoading();
  const { stepForward } = useStep();
  const { addToast } = useToast();
  const { createCharge } = useCharge();

  const formRef = useRef<FormHandles>(null);

  const [zipcode, setZipcode] = useState('');
  const [captchaToken, setCaptchaToken] = React.useState<string | null>(null);

  const handleSubmit = useCallback(
    async (data: ICreditCardRegistrationData) => {
      startLoading();

      formRef.current?.setErrors({});

      const { deviceId, hashedDeviceId } = await generateDeviceId();

      try {
        const schema = Yup.object().shape({
          name: Yup.string()
            .test(
              'isFullName',
              'Digite seu nome completo',
              value => value?.trim().includes(' ') || false,
            )
            .required('Nome completo obrigatório'),
          cpf: Yup.string()
            .test('isCPF', 'Digite um CPF válido', value =>
              validateCPF(value || ''),
            )
            .required('CPF obrigatório'),
          zipcode: Yup.string()
            .length(9, 'Digite um CEP válido')
            .test('isCep', 'Digite um CEP válido', value =>
              validateCep(value || ''),
            )
            .required('CEP obrigatório'),
          street: Yup.string().required('Endereço obrigatório'),
          city: Yup.string().required('Cidade obrigatória'),
          state: Yup.string().required('Estado obrigatório'),
          number: Yup.string()
            .min(16, 'Número do cartão inválido')
            .required('Número do cartão obrigatório'),
          expiration_date: Yup.string()
            .test('isValid', 'Validade inválida', value => {
              if (!value) {
                return false;
              }

              const [month, year] = value.split('/');

              if (+month > 12 || +month < 1) {
                return false;
              }

              if (
                isPast(lastDayOfMonth(new Date(+year + 2000, +month - 1, 1)))
              ) {
                return false;
              }

              return true;
            })
            .required('Validade obrigatória'),
          cvv: Yup.string().min(3, 'CVV inválido').required('CVV obrigatório'),
        });

        await schema.validate(data, { abortEarly: false });

        if (!captchaToken) {
          addToast({
            title: 'Erro no CAPTCHA',
            description: 'Por favor, cumpra a etapa de verificação.',
            type: 'error',
          });

          stopLoading();
          return;
        }

        const [exp_month, exp_year] = data.expiration_date.split('/');

        const tokenResponse = await axios.post(
          `https://api.pagar.me/core/v5/tokens?appId=${process.env.REACT_APP_PAGARME_PUBLIC_KEY}`,
          {
            type: 'card',
            card: {
              number: data.number.replace(/\D/g, ''),
              holder_name: removeSpecialCharacters(data.name),
              exp_month,
              exp_year,
              cvv: data.cvv,
            },
          },
        );

        const response = await api.post('/purchases', {
          product_id: product.id,
          payment_method: 'credit_card',
          coupon_code: product.coupon_code,
          installments: 1,
          card_token: tokenResponse.data.id,
          cpf: data.cpf.replace(/\D/g, ''),
          name: data.name,
          zipcode: data.zipcode.replace(/\D/g, ''),
          street: data.street,
          city: data.city,
          state: data.state,
          device_id: deviceId,
          hashed_device_id: hashedDeviceId,
          recaptcha_token: captchaToken,
        });

        createCharge({
          type: 'credit_card',
          payment_ext: response.data.payment_ext,
        });

        stepForward();

        stopLoading();
      } catch (err) {
        stopLoading();

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          addToast({
            title: 'Erro de validação',
            description: 'Verifique os campos com erro e tente novamente.',
            type: 'error',
          });

          return;
        }

        if (axios.isAxiosError(err)) {
          const { errors } = err.response?.data || { errors: null };
          let { error } = err.response?.data || {
            error: 'default',
          };

          if (typeof error === 'object') {
            if (typeof error.message === 'string') {
              error = error.message;
            } else {
              error = getPagarmeErrors(error, 'object');
            }
          }

          if (errors) {
            error = getPagarmeErrors(errors, 'array');
          }

          switch (error) {
            case 'Could not detect card brand.':
              addToast({
                title: 'Número do cartão inválido',
                description:
                  'Não foi possível detectar a bandeira do seu cartão de crédito. Verifique o número e tente novamente.',
                type: 'error',
              });
              break;

            case 'The number field is not a valid card number':
              addToast({
                title: 'Número do cartão inválido',
                description:
                  'O número informado do cartão de crédito é inválido. Verifique o número e tente novamente.',
                type: 'error',
              });
              break;

            case 'not_authorized':
              addToast({
                title: 'Não autorizado',
                description:
                  'A sua compra não foi autorizada pela sua operadora de cartões. Por favor, entre em contato com seu banco e tente novamente!',
                type: 'error',
              });
              break;

            default:
              addToast({
                title: 'Erro Genério',
                description: error,
                type: 'error',
              });
              break;
          }
        }
      }
    },
    [
      startLoading,
      stopLoading,
      stepForward,
      createCharge,
      addToast,
      product,
      captchaToken,
    ],
  );

  return (
    <Container>
      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        noValidate
        data-pagarmecheckout-form
      >
        <img src={flagsImage} alt="" />

        <InputMask
          name="number"
          label="Número do cartão"
          mask="9999 9999 9999 9999"
          maskChar={null}
          placeholder="0000 0000 0000 0000"
        />

        <Input
          name="name"
          label="Nome completo do titular"
          placeholder="Antônio José da Silva Oliveira"
        />

        <div className="split">
          <InputMask
            name="expiration_date"
            label="Validade"
            mask="99/99"
            maskChar={null}
            placeholder="MM/AA"
          />

          <Input
            name="cvv"
            label="CVV"
            maxLength={4}
            type="text"
            placeholder="000"
          />
        </div>

        <div className="split">
          <InputMask
            name="cpf"
            label="CPF do titular"
            mask="999.999.999-99"
            maskChar={null}
            placeholder="000.000.000-00"
          />

          <InputMask
            name="zipcode"
            label="CEP"
            type="text"
            mask="99999-999"
            maskChar={null}
            placeholder="00000-000"
            onInput={e => {
              setZipcode(e.currentTarget.value.replace(/\D/g, '').slice(0, 8));
            }}
          />
        </div>

        <Address zipcode={zipcode} formRef={formRef} />

        <div style={{ margin: '12px 0 20px 0' }}>
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}
            onChange={value => setCaptchaToken(value)}
          />
        </div>

        <Button color="primary" type="submit">
          Confirmar compra
        </Button>
      </Form>
    </Container>
  );
};

export default CreditCard;
