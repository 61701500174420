import FingerprintJS from '@fingerprintjs/fingerprintjs';
import CryptoJS from 'crypto-js';

export const generateDeviceId = async (): Promise<{
  deviceId: string;
  hashedDeviceId: string;
}> => {
  const fp = await FingerprintJS.load();

  const result = await fp.get();
  const deviceId = result.visitorId;

  const secret = process.env.REACT_APP_FINGERPRINT_PUBLIC;

  if (!secret) {
    throw new Error('Fingerprint secret not found');
  }

  const hash = CryptoJS.HmacSHA256(deviceId, secret).toString();

  return {
    deviceId,
    hashedDeviceId: hash,
  };
};

export default generateDeviceId;
