import React, { useMemo, useState } from 'react';
import { SwitchTransition, CSSTransition } from 'react-transition-group';

import { BsCreditCard2Back } from 'react-icons/bs';

import Summary from '../Summary';
import Billet from './Billet';
import CreditCard from './CreditCard';
import Pix from './Pix';

import { Container, OptionButton } from './styles';

const PaymentMethod: React.FC = () => {
  const [paymentMethod, setPaymentMethod] = useState(0);

  const paymentMethodComponents = useMemo(
    () => [<Pix />, <Billet />, <CreditCard />],
    [],
  );

  return (
    <Summary>
      <Container>
        <h2>Formas de pagamento</h2>

        <ul>
          <li>
            <OptionButton
              type="button"
              onClick={() => setPaymentMethod(0)}
              selected={paymentMethod === 0}
              className={paymentMethod === 0 ? 'selected' : ''}
            >
              {/* <img src={pixLogo} alt="" /> */}

              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 18 18"
                xmlSpace="preserve"
              >
                <g id="g992" transform="translate(782.8699,645.7084)">
                  <path
                    id="path994"
                    className="st0"
                    d="M-768.8-631.9c-0.7,0-1.4-0.3-1.9-0.8l-2.7-2.7c-0.2-0.2-0.5-0.2-0.7,0l-2.7,2.7
		c-0.5,0.5-1.2,0.8-1.9,0.8h-0.5l3.4,3.4c1.1,1.1,2.8,1.1,3.9,0l3.4-3.4H-768.8z"
                  />
                </g>
                <g id="g996" transform="translate(666.4241,758.5073)">
                  <path
                    id="path998"
                    className="st0"
                    d="M-662.2-754.3c0.7,0,1.4,0.3,1.9,0.8l2.7,2.7c0.2,0.2,0.5,0.2,0.7,0l2.7-2.7
		c0.5-0.5,1.2-0.8,1.9-0.8h0.3l-3.4-3.4c-1.1-1.1-2.8-1.1-3.9,0l-3.4,3.4H-662.2z"
                  />
                </g>
                <g id="g1000" transform="translate(820.2131,724.8881)">
                  <path
                    id="path1002"
                    className="st0"
                    d="M-803-717.8l-2.1-2.1c0,0-0.1,0-0.2,0h-0.9c-0.5,0-1,0.2-1.3,0.5l-2.7,2.7
		c-0.2,0.2-0.6,0.4-0.9,0.4c-0.3,0-0.7-0.1-0.9-0.4l-2.7-2.7c-0.3-0.3-0.8-0.5-1.3-0.5h-1.2c-0.1,0-0.1,0-0.1,0l-2.1,2.1
		c-1.1,1.1-1.1,2.8,0,3.9l2.1,2.1c0,0,0.1,0,0.1,0h1.2c0.5,0,1-0.2,1.3-0.5l2.7-2.7c0.5-0.5,1.3-0.5,1.8,0l2.7,2.7
		c0.3,0.3,0.8,0.5,1.3,0.5h0.9c0.1,0,0.1,0,0.2,0l2.1-2.1C-801.9-715-801.9-716.8-803-717.8"
                  />
                </g>
              </svg>
              <span>PIX</span>
            </OptionButton>
          </li>
          {/* <li>
            <OptionButton
              type="button"
              onClick={() => setPaymentMethod(1)}
              selected={paymentMethod === 1}
            >
              Boleto bancário
            </OptionButton>
          </li> */}
          <li>
            <OptionButton
              type="button"
              onClick={() => setPaymentMethod(2)}
              selected={paymentMethod === 2}
              className={paymentMethod === 2 ? 'selected' : ''}
            >
              <BsCreditCard2Back size={18} />
              Cartão de crédito
            </OptionButton>
          </li>
        </ul>

        <SwitchTransition mode="out-in">
          <CSSTransition
            key={['pix', 'billet', 'credit_card'][paymentMethod]}
            addEndListener={(node, done) => {
              node.addEventListener('transitionend', done, false);
            }}
            classNames="fade"
          >
            {paymentMethodComponents[paymentMethod]}
          </CSSTransition>
        </SwitchTransition>
      </Container>
    </Summary>
  );
};

export default PaymentMethod;
