import React from 'react';
import { Switch, Route } from 'react-router-dom';

import ScrollToTop from './ScrollToTop';

import Default from '../pages/Default';

const Routes: React.FC = () => (
  <ScrollToTop>
    <Switch>
      <Route path="/:product_id" exact component={Default} />
      <Route path="/" component={Default} />
    </Switch>
  </ScrollToTop>
);

export default Routes;
