import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { AxiosError } from 'axios';

import api from '../services/api';

interface IFailedRequest {
  onSuccess(token: string): void;
  onFailure(err: AxiosError): void;
}
interface User {
  id: string;
  first_name: string;
  last_name: string;
  cpf: string;
  show_name: string;
  role_name: string;
  avatar_url: string;
  role: string;
}

interface AuthState {
  token: string;
  refreshToken: string;
  user: User;
}

interface LoginCredentials {
  email: string;
  password: string;
}

interface AuthContextState {
  user: User;
  isAuthenticated: boolean;
  updateUser(userData: User): void;
  login(credentials: LoginCredentials): Promise<void>;
  logout(): void;
}

const AuthContext = createContext<AuthContextState>({} as AuthContextState);

const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<User>({} as User);
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!localStorage.getItem('@Educacione:token'),
  );

  const clearStorage = () => {
    localStorage.removeItem('@Educacione:token');
    localStorage.removeItem('@Educacione:refreshToken');

    setUser({} as User);
    setIsAuthenticated(false);
  };

  const logout = useCallback(() => {
    clearStorage();
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('@Educacione:token');

    if (token) {
      api
        .get('/profiles/me')
        .then(response => {
          const userData = response.data;

          setUser({
            ...userData.user,
            courses: userData.courses,
            forums: userData.forums,
          });

          setIsAuthenticated(true);
        })
        .catch(() => {
          logout();
        });
    }
  }, [logout]);

  const login = useCallback(async ({ email, password }) => {
    const response = await api.post<AuthState>('/sessions', {
      email,
      password,
    });

    const { token, refreshToken } = response.data;

    localStorage.setItem('@Educacione:token', token);
    localStorage.setItem('@Educacione:refreshToken', refreshToken);

    api.defaults.headers.common.Authorization = `Bearer ${token}`;

    const profileResponse = await api.get('/profiles/me');

    setUser({
      ...profileResponse.data.user,
      courses: profileResponse.data.courses,
      forums: profileResponse.data.forums,
    });

    setIsAuthenticated(true);
  }, []);

  const updateUser = useCallback((userData: User) => {
    setUser(userData);
  }, []);

  let refreshToken = localStorage.getItem('@Educacione:refreshToken');
  let isRefreshing = false;
  let failedRequestsQueue: IFailedRequest[] = [];

  api.interceptors.response.use(
    response => response,
    (error: AxiosError) => {
      if (error.response?.status === 401) {
        if (error.response.data?.error === 'token-expired') {
          refreshToken = localStorage.getItem('@Educacione:refreshToken');
          const originalConfig = error.config;

          if (!isRefreshing) {
            isRefreshing = true;

            api
              .post('/refresh-tokens', { token: refreshToken })
              .then(response => {
                localStorage.setItem('@Educacione:token', response.data.token);
                localStorage.setItem(
                  '@Educacione:refreshToken',
                  response.data.refreshToken,
                );

                api.defaults.headers.common.Authorization = `Bearer ${response.data.token}`;

                failedRequestsQueue.forEach(request =>
                  request.onSuccess(response.data.token),
                );
                failedRequestsQueue = [];
              })
              .catch(err => {
                failedRequestsQueue.forEach(request => request.onFailure(err));
                failedRequestsQueue = [];
              })
              .finally(() => {
                isRefreshing = false;
              });
          }

          return new Promise((resolve, reject) => {
            failedRequestsQueue.push({
              onSuccess: (newToken: string) => {
                if (originalConfig.headers)
                  originalConfig.headers.Authorization = `Bearer ${newToken}`;

                resolve(api(originalConfig));
              },
              onFailure: (err: AxiosError) => {
                reject(err);
              },
            });
          });
        }

        logout();
      }

      return Promise.reject(error);
    },
  );

  const authValues = useMemo(
    () => ({ user, updateUser, login, logout, isAuthenticated }),
    [user, updateUser, login, logout, isAuthenticated],
  );

  return (
    <AuthContext.Provider value={authValues}>{children}</AuthContext.Provider>
  );
};

function useAuth(): AuthContextState {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be user within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
