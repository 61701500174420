import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { CSSTransition } from 'react-transition-group';

import LoaderPureCss from '../components/LoaderPureCss';

interface LoadingContextState {
  startLoading(): void;
  stopLoading(): void;
  isLoading: boolean;
}

const LoadingContext = createContext<LoadingContextState>(
  {} as LoadingContextState,
);

const LoadingProvider: React.FC = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    document.body.style.overflow = isLoading ? 'hidden' : 'auto';
  }, [isLoading]);

  const startLoading = useCallback(() => {
    setIsLoading(true);
  }, []);

  const stopLoading = useCallback(() => {
    setIsLoading(false);
  }, []);

  const loadingValues = useMemo(
    () => ({ startLoading, stopLoading, isLoading }),
    [startLoading, stopLoading, isLoading],
  );

  return (
    <LoadingContext.Provider value={loadingValues}>
      {children}
      <CSSTransition
        in={isLoading}
        timeout={300}
        classNames="fade"
        unmountOnExit
      >
        <LoaderPureCss background="#6c23c020" isFixed />
      </CSSTransition>
    </LoadingContext.Provider>
  );
};

function useLoading(): LoadingContextState {
  const context = useContext(LoadingContext);

  if (!context) {
    throw new Error('useLoading must be user within an LoadingProvider');
  }

  return context;
}

export { LoadingProvider, useLoading };
