import React, { useCallback, useRef } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import Button from '../../../../../components/Button';
import Input from '../../../../../components/Input';
import { useLoading } from '../../../../../hooks/loading';
import getValidationErrors from '../../../../../utils/getValidationErrors';
import { useToast } from '../../../../../hooks/toast';
import api from '../../../../../services/api';
import { useModal } from '../../../../../hooks/modal';

// import { Container } from './styles';

interface IForgotPasswordData {
  email: string;
}

const ForgotPassword: React.FC = () => {
  const { addToast } = useToast();
  const { clearModals } = useModal();
  const { startLoading, stopLoading } = useLoading();

  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: IForgotPasswordData) => {
      startLoading();

      formRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          email: Yup.string()
            .email('E-mail inválido')
            .required('E-mail obrigatório'),
        });

        await schema.validate(data, { abortEarly: false });

        await api.post('/passwords/forgot', {
          email: data.email,
        });

        addToast({
          type: 'success',
          title: 'Sucesso',
          description:
            'Enviamos um link para a redefinição da sua senha por e-mail.',
        });

        stopLoading();
        clearModals();
      } catch (err) {
        stopLoading();

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          addToast({
            title: 'Erro de validação',
            description: 'Verifique os campos com erro e tente novamente.',
            type: 'error',
          });

          return;
        }

        addToast({
          type: 'error',
          title: 'E-mail inexistente',
          description: 'Não existe nenhum aluno cadastrado com esse e-mail.',
        });
      }
    },
    [addToast, startLoading, stopLoading, clearModals],
  );

  return (
    <Form ref={formRef} onSubmit={handleSubmit} noValidate>
      <Input name="email" label="E-mail" type="email" />

      <Button color="primary" type="submit">
        Recuperar senha
      </Button>
    </Form>
  );
};

export default ForgotPassword;
