import React, { useEffect, useRef } from 'react';
import { MdClose } from 'react-icons/md';

import { IModalProps, useModal } from '../../../hooks/modal';

import { Container } from './styles';

interface ModalProps {
  modal: IModalProps;
}

const Modal: React.FC<ModalProps> = ({ modal }) => {
  const modalRef = useRef<HTMLDivElement>(null);

  const { removeModal } = useModal();

  return (
    <Container>
      <div>
        <header>
          <strong>{modal.title}</strong>

          <button onClick={() => removeModal(modal.id)} type="button">
            <MdClose size={18} />
          </button>
        </header>

        <div>{modal.content}</div>
      </div>
    </Container>
  );
};

export default Modal;
