import React, { createContext, useContext, useMemo } from 'react';

export interface IProductData {
  id: string;
  name: string;
  duration_in_months: number;
  price: number;
  discount: number;
  final_price: number;
  coupon_code: string | null;
}

interface IProductContextData {
  product: IProductData;
}

interface IProductProviderProps {
  product: IProductData;
}

const ProductContext = createContext<IProductContextData>(
  {} as IProductContextData,
);

const ProductProvider: React.FC<IProductProviderProps> = ({
  children,
  product,
}) => {
  const contextValues = useMemo(() => ({ product }), [product]);

  return (
    <ProductContext.Provider value={contextValues}>
      {children}
    </ProductContext.Provider>
  );
};

function useProduct(): IProductContextData {
  const context = useContext(ProductContext);

  if (!context) {
    throw new Error('useProduct must be used within a ProductProvider');
  }

  return context;
}

export { ProductProvider, useProduct };
