import styled from 'styled-components';

export const Container = styled.div`
  > form > footer {
    display: flex;
    justify-content: space-between;
  }
`;

export const Forgot = styled.button`
  border: none;
  background: none;
  align-self: flex-start;
  font-size: 14px;
  color: #132f60;
  border-bottom: 1px solid #132f60;
  transition: border-color 0.2s ease;

  &:hover {
    border-bottom: transparent;
  }
`;
