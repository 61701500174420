import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { isBefore, subYears } from 'date-fns';
import React, { useCallback, useRef, useState } from 'react';
import { validateCPF, validatePhone } from 'validations-br';
import * as Yup from 'yup';
import axios from 'axios';

import ReCAPTCHA from 'react-google-recaptcha';
import { useToast } from '../../../../hooks/toast';
import { useAuth } from '../../../../hooks/auth';

import api from '../../../../services/api';

import getValidationErrors from '../../../../utils/getValidationErrors';

import Button from '../../../../components/Button';
import Checkbox from '../../../../components/Checkbox';
import Input from '../../../../components/Input';
import InputMask from '../../../../components/InputMask';

import Container from './styles';
import { useLoading } from '../../../../hooks/loading';
import InputEmail from '../../../../components/InputEmail';

interface IUserRegistrationData {
  first_name: string;
  last_name: string;
  email: string;
  email_confirmation: string;
  birthday: string;
  cpf: string;
  password: string;
  password_confirmation: string;
  phone_number: string;
}

interface IUserRegistrationProps {
  setHaveAccount(value: boolean): void;
  setDefaultEmail(value: string): void;
}

function isPaste(event: React.KeyboardEvent<HTMLInputElement>) {
  if (event.ctrlKey && event.key === 'v') {
    return true;
  }

  return false;
}

const UserRegistration: React.FC<IUserRegistrationProps> = ({
  setHaveAccount,
  setDefaultEmail,
}) => {
  const { addToast } = useToast();
  const { login } = useAuth();
  const { startLoading, stopLoading } = useLoading();

  const formRef = useRef<FormHandles>(null);

  const [checkedTerms, setCheckedTerms] = useState(false);
  const [captchaToken, setCaptchaToken] = React.useState<string | null>(null);

  const handleSubmit = useCallback(
    async (data: IUserRegistrationData) => {
      startLoading();

      formRef.current?.setErrors({});

      try {
        if (!captchaToken) {
          addToast({
            title: 'Erro no CAPTCHA',
            description: 'Por favor, cumpra a etapa de verificação.',
            type: 'error',
          });

          stopLoading();
          return;
        }

        const schema = Yup.object().shape({
          first_name: Yup.string().required('Nome obrigatório'),
          last_name: Yup.string().required('Sobrenome obrigatório'),
          cpf: Yup.string()
            .test('isCPF', 'Digite um CPF válido', value =>
              validateCPF(value || ''),
            )
            .required('CPF obrigatório'),
          email: Yup.string()
            .email('Digite um e-mail válido')
            .required('E-mail obrigatório'),
          email_confirmation: Yup.string().oneOf(
            [Yup.ref('email'), null],
            'E-mails não conferem',
          ),
          password: Yup.string()
            .min(6, 'Mínimo de 6 caracteres')
            .required('Senha obrigatória'),
          password_confirmation: Yup.string().oneOf(
            [Yup.ref('password'), null],
            'Senhas  não conferem',
          ),
          birthday: Yup.string()
            .required('Data de nascimento obrigatória')
            .test('isValid', 'Mínimo 14 anos', value => {
              if (!value) {
                return false;
              }

              return isBefore(new Date(value), subYears(new Date(), 14));
            })
            .nullable(),
          phone_number: Yup.string()
            .required('Telefone obrigatório')
            .test('isPhone', 'Digite um telefone válido', value =>
              validatePhone(value || ''),
            ),
          terms: Yup.boolean()
            .required()
            .oneOf(
              [true],
              'Para continuar, você deve aceitar os termos e a política',
            ),
        });

        await schema.validate(data, { abortEarly: false });

        await api.post('/users', {
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          email_confirmation: data.email_confirmation,
          birthday: data.birthday,
          cpf: data.cpf.replace(/\D/g, ''),
          password: data.password,
          phone_number: data.phone_number.replace(/\D/g, ''),
        });

        login({ email: data.email, password: data.password });

        stopLoading();
      } catch (err) {
        stopLoading();

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          addToast({
            title: 'Erro de validação',
            description: 'Verifique os campos com erro e tente novamente.',
            type: 'error',
          });

          return;
        }

        if (axios.isAxiosError(err)) {
          const { error } = err.response?.data || { error: 'default' };

          switch (error) {
            case 'user-already-exists':
              addToast({
                type: 'error',
                title: 'Usuário Duplicado',
                description:
                  'Parece que você já está cadastrado em nosso sistema.',
              });

              setDefaultEmail(data.email);
              setHaveAccount(true);
              break;

            case 'Bad Request':
              addToast({
                type: 'error',
                title: 'E-mail inválido',
                description:
                  'O e-mail que você digitou é inválido! Verifique o campo e tente novamente.',
              });
              break;

            default:
              addToast({
                title: 'Erro Genério',
                description: error,
                type: 'error',
              });
              break;
          }

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description:
            'Ocorreu um erro em nosso sistema. Por favor, entre em contato com o suporte através do e-mail suporte@educacione.com.br.',
        });
      }
    },
    [
      addToast,
      login,
      setHaveAccount,
      setDefaultEmail,
      startLoading,
      stopLoading,
      captchaToken,
    ],
  );

  return (
    <Container>
      <Form ref={formRef} onSubmit={handleSubmit} noValidate>
        <div className="split">
          <Input name="first_name" label="Nome" />

          <Input name="last_name" label="Sobrenome" />
        </div>

        <InputMask
          type="text"
          mask="999.999.999-99"
          maskChar={null}
          name="cpf"
          label="CPF"
        />

        <div className="split">
          <Input name="password" type="password" label="Senha" isPassword />

          <Input
            name="password_confirmation"
            type="password"
            label="Confirme a senha"
            isPassword
            onKeyDown={event => {
              if (isPaste(event)) {
                event.preventDefault();
                event.stopPropagation();
              }
            }}
          />
        </div>

        <InputEmail name="email" label="E-mail" />

        <InputEmail
          name="email_confirmation"
          label="Confirme o E-mail"
          onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
            if (isPaste(event)) {
              event.preventDefault();
              event.stopPropagation();
            }
          }}
        />

        <div className="split">
          <Input type="date" name="birthday" label="Data de nascimento" />

          <InputMask
            mask=""
            maskChar={null}
            type="text"
            name="phone_number"
            label="Telefone"
            isPhone
          />
        </div>

        <Checkbox
          name="terms"
          label={
            <>
              Li e aceito os{' '}
              <a
                href="https://educacione.com.br/termos-de-uso"
                target="_blank"
                rel="noreferrer"
              >
                Termos de Uso
              </a>{' '}
              e a{' '}
              <a
                href="https://educacione.com.br/politica-de-privacidade"
                target="_blank"
                rel="noreferrer"
              >
                Política de Privacidade
              </a>
              .
            </>
          }
          checked={checkedTerms}
          onChange={() => setCheckedTerms(isChecked => !isChecked)}
        />

        <div style={{ margin: '24px 0' }}>
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}
            onChange={value => setCaptchaToken(value)}
          />
        </div>

        <Button color="primary" type="submit">
          Continuar
        </Button>
      </Form>
    </Container>
  );
};

export default UserRegistration;
