import styled, { css } from 'styled-components';

interface IStepProps {
  active: boolean;
}

export const Container = styled.ul`
  display: flex;
  justify-content: space-between;
  width: 288px;
  margin: 40px auto 64px auto;
`;

export const Step = styled.li<IStepProps>`
  width: 33%;
  list-style-type: none;
  float: left;
  font-size: 12px;
  position: relative;
  text-align: center;
  text-transform: uppercase;

  ::before {
    content: '';
    width: 24px;
    height: 24px;
    line-height: 24px;
    border: 2px solid #6c23c0;
    display: block;
    margin: 0 auto 10px auto;
    border-radius: 50%;
  }

  ::after {
    content: '';
    width: 70px;
    height: 2px;
    position: absolute;
    background-color: #6c23c0;
    top: 13px;
    left: -36px;
    z-index: -1;
  }

  :first-child::after {
    content: none;
  }

  ${({ active }) =>
    active &&
    css`
      color: #6c23c0;

      &::before {
        background-color: #6c23c0;
      }
    `}
`;
