import React from 'react';
import { useStep } from '../../hooks/steps';

import { Container, Step } from './styles';

interface IStepProgressProps {
  steps: string[];
}

const StepProgress: React.FC<IStepProgressProps> = ({ steps }) => {
  const { currentStep } = useStep();

  return (
    <Container>
      {steps.map((step, index) => (
        <Step key={+index} active={currentStep === index}>
          {step}
        </Step>
      ))}
    </Container>
  );
};

export default StepProgress;
