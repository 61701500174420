import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  background: #f6f6fa;
  border-radius: 8px;
  padding: 32px;
  max-width: 480px;
  margin: 0 auto;
  margin-top: 80px;

  > h1 {
    color: #132f60;
  }

  > p {
    line-height: 1.5;
    margin-top: 24px;

    a {
      color: #7361cb;
      text-decoration: none;
      transition: color 0.2s ease;

      &:hover {
        color: ${shade(0.2, '#7361cb')};
      }
    }
  }
`;

export default Container;
