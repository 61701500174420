import React from 'react';

import { Container } from './styles';

export interface LoaderProps {
  isFixed?: boolean;
  scale?: string;
  background?: string;
  borderRadius?: string;
}

const LoaderPureCss: React.FC<LoaderProps> = ({
  isFixed = false,
  scale,
  background,
  borderRadius,
}) => {
  return (
    <Container
      isFixed={isFixed}
      scale={scale}
      background={background}
      borderRadius={borderRadius}
    >
      <div>
        <span />
      </div>
    </Container>
  );
};

export default LoaderPureCss;
